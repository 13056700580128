<template>
	<div class="redPack app_flex" :style="isApp ? 'padding-top: 1.3rem;box-sizing: border-box' : ''">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="红包" right-text="红包规则" left-arrow @click-left="fLeft"
					@click-right="fOpenRule" />
			</van-sticky>
		</div>
		<p class="redPack_tips">*只有跟单的用户才能抢红包哦</p>

		<div v-if="isShowTabs" class="redPack_body app_scroll">
			<van-list v-model:loading="oRedPack.loading" v-model:error="oRedPack.error" error-text="请求失败，点击重新加载"
				:finished="oRedPack.finished" finished-text="没有更多了" @load="fOnLoad">
				<div class="redPack_list" v-for="(item, index) in oRedPack.list" :key="index"
					@click="fOpenRedPack(item)">
<!--					<img class="redPack_list_a" :src="item.createrHead ? item.createrHead : defaultAvatar" alt="">-->
					<div class="redPack_list_b">
<!--						<p class="redPack_list_b_a">{{item.createrName}}</p>-->
						<div class="redPack_list_b_b"
							:class="{'redPack_list_b_b--overdue' : item.overdue || item.none || item.gained}">
							<div class="redPack_list_b_b_a">
								<p class="redPack_list_b_b_a_a">¥ {{item.redpackAssets}}</p>
								<p class="redPack_list_b_b_a_b">大吉大利，一起红单</p>
							</div>
							<img v-if="item.none" class="redPack_list_b_b_b" src="~@/assets/images/img_033.png" alt="">
							<img v-else-if="item.overdue" class="redPack_list_b_b_b" src="~@/assets/images/img_026.png"
								alt="">
							<img v-else-if="item.gained" class="redPack_list_b_b_b" src="~@/assets/images/img_034.png"
								alt="">
						</div>
					</div>
				</div>
			</van-list>
		</div>

		<div v-else class="redPack_body redPack_body--tabs app_scroll">
			<van-tabs v-model:active="active" swipeable title-active-color="#383838" title-inactive-color="#979797"
				line-width="0.48rem" @rendered="fOnRendered">
				<van-tab title="待跟单" name="zero">
					<van-list v-model:loading="oRedPack.loading" v-model:error="oRedPack.error" error-text="请求失败，点击重新加载"
						:finished="oRedPack.finished" finished-text="没有更多了" @load="fOnLoad">
						<div class="redPack_list" v-for="(item, index) in oRedPack.list" :key="index"
							@click="fOpenRedPack(item)">
							<img class="redPack_list_a" :src="item.createrHead ? item.createrHead : defaultAvatar" alt="">
							<div class="redPack_list_b">
								<p class="redPack_list_b_a">{{item.createrName}}</p>
								<div class="redPack_list_b_b"
									:class="{'redPack_list_b_b--overdue' : item.overdue || item.none || item.gained}">
									<div class="redPack_list_b_b_a">
										<p class="redPack_list_b_b_a_a">¥ {{item.redpackAssets}}</p>
										<p class="redPack_list_b_b_a_b">大吉大利，一起红单</p>
									</div>
									<img v-if="item.none" class="redPack_list_b_b_b" src="~@/assets/images/img_033.png" alt="">
									<img v-else-if="item.overdue" class="redPack_list_b_b_b" src="~@/assets/images/img_026.png"
										alt="">
									<img v-else-if="item.gained" class="redPack_list_b_b_b" src="~@/assets/images/img_034.png"
										alt="">
								</div>
							</div>
						</div>
					</van-list>
				</van-tab>
				<van-tab title="已跟单" name="one">
					<van-list v-model:loading="oRedPackFollow.loading" v-model:error="oRedPackFollow.error" error-text="请求失败，点击重新加载"
						:finished="oRedPackFollow.finished" finished-text="没有更多了" @load="fOnLoadFollow">
						<div class="redPack_list" v-for="(item, index) in oRedPackFollow.list" :key="index"
							@click="fOpenRedPack(item)">
							<img class="redPack_list_a" :src="item.createrHead ? item.createrHead : defaultAvatar" alt="">
							<div class="redPack_list_b">
								<p class="redPack_list_b_a">{{item.createrName}}</p>
								<div class="redPack_list_b_b"
									:class="{'redPack_list_b_b--overdue' : item.overdue || item.none || item.gained}">
									<div class="redPack_list_b_b_a">
										<p class="redPack_list_b_b_a_a">¥ {{item.redpackAssets}}</p>
										<p class="redPack_list_b_b_a_b">大吉大利，一起红单</p>
									</div>
									<img v-if="item.none" class="redPack_list_b_b_b" src="~@/assets/images/img_033.png" alt="">
									<img v-else-if="item.overdue" class="redPack_list_b_b_b" src="~@/assets/images/img_026.png"
										alt="">
									<img v-else-if="item.gained" class="redPack_list_b_b_b" src="~@/assets/images/img_034.png"
										alt="">
								</div>
							</div>
						</div>
					</van-list>
				</van-tab>
			</van-tabs>
		</div>

		<h-dialog-c title="提示" dialogKey="oDialogA" :show="oDialogA.showDialog" :showCancelButton="true"
			confirmButtonText="确定" @fBeforeClose="fBeforeClose1">
			<div class="redPack_tips_a">
				{{oDialogA.title}}
			</div>
		</h-dialog-c>
		<van-overlay :show="oDialogB.showDialog" @click="oDialogB.showDialog = false">
			<div class="redPack_wrapper" @click.stop>
				<template v-if="sSelectRedPackStatus === 'INIT' || sSelectRedPackStatus === 'GAINABLE'">
					<div class="redPack_wrapper_content">
						<img class="redPack_wrapper_content_a"
							:src="oSelectRedPack.createrHead ? oSelectRedPack.createrHead : defaultAvatar" alt="">
						<p class="redPack_wrapper_content_b">{{oSelectRedPack.createrName}}的红包</p>
						<p class="redPack_wrapper_content_c">大吉大利，一起红单！</p>
						<p class="redPack_wrapper_content_d">¥ {{oSelectRedPack.redpackAssets}}</p>

						<div class="redPack_wrapper_content_e">
							<img v-if="sSelectRedPackOpenStatus === 'INIT'" class="redPack_wrapper_content_e_a" src="~@/assets/images/img_029.png" @click="fLoot">
							<img v-else-if="sSelectRedPackOpenStatus === 'LOADING'" class="redPack_wrapper_content_e_b" src="~@/assets/images/img_035.gif">
						</div>
					</div>

					<img class="redPack_wrapper_close" src="~@/assets/icon/icon-019.png" alt=""
						@click="oDialogB.showDialog = false">
				</template>
				<template v-else>
					<div class="redPack_wrapper_contentA">
						<img class="redPack_wrapper_contentA_a" src="~@/assets/images/img_029.png" alt="">
						<p class="redPack_wrapper_contentA_b">{{oSelectRedPack.createrName}}的红包</p>
						<p class="redPack_wrapper_contentA_c" v-if="sSelectRedPackStatus === 'OVERDUE'">来晚了，红包过期了</p>
						<p class="redPack_wrapper_contentA_c" v-else-if="sSelectRedPackStatus === 'NONE'">手慢了，红包派完了</p>
						<p class="redPack_wrapper_contentA_c" v-else>大吉大利，一起红单！</p>

						<div class="redPack_wrapper_contentA_d" @click="fGoDetail">
							<p>查看领取详情</p>
							<img src="~@/assets/icon/icon-020.png" alt="">
						</div>
					</div>
				</template>
			</div>
		</van-overlay>
		<h-dialog-a title="红包规则" dialogKey="oDialogC" :show="oDialogC.showDialog" :showCancelButton="oDialogC.showCancelButton" :showConfirmButton="oDialogC.showConfirmButton" @fBeforeClose="fBeforeClose">
			<div class="redPack_tips_b">
				<p>1、必须跟单后才可领取对应发单的红包</p>
				<p>2、同一个发单不论跟几次单,同一个红包只能领取一次</p>
				<p>3、同一个发单如果发了多个红包,只要跟单后每个红包都可领取一次</p>
			</div>
		</h-dialog-a>
	</div>
</template>

<script>
	import {
		ref,
		reactive
	} from 'vue';
	import {
		useStore
	} from "vuex";
	import {
		useRoute,
		useRouter
	} from "vue-router";
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import {
		fnJumpApp,
		fnCopyInnerText,
		uaFun
	} from "@/utils/public.js";
	import {
		apiRedpackList,
		apiPlanRedpackList,
		apiRedpackOpen,
		apiRedpackLoot
	} from "@/api/redPage.js";
	import HDialogA from "@/components/HDialog/HDialogA/index.vue";
	import HDialogC from "@/components/HDialog/HDialogC/index.vue";
	import defaultAvatar from "@/assets/images/img_032.png";
	import {Toast} from "vant";
	export default {
		name: "RedPack",
		components: {
			HDialogA,
			HDialogC
		},
		setup() {
			const isApp = uaFun()
			const route = useRoute();
			const route_query = route.query;
			console.log(route_query);
			const router = useRouter(); //初始化路由
			const store = useStore(); // 使用useStore方法
			const UserInfo = store.state.userInfo.userInfo;
			const active = ref("zero");
			active.value = route_query.type ? route_query.type : "zero";
			const isShowTabs = ref(true);

			// 方案
			const oRedPack = reactive({
				current: 0,
				size: 10,
				list: [],
				loading: false,
				finished: false
			});
			const fGetPlanRedpackList = () => {
				apiPlanRedpackList({
					// planId: route_query.planId,
					planId: route_query.shopPlanId,
					roleId: UserInfo.roleId
				}).then(res => {
					console.log(res);
					let tData = res && res.result ? res.result : [];
					oRedPack.list = tData;
					oRedPack.finished = true;
					oRedPack.loading = false;
				}).catch(() => {
					oRedPack.loading = false;
					oRedPack.error = true;
				});
			};
			// 加载
			const fOnLoad = () => {
				if (route_query.planId || route_query.shopPlanId) {
					isShowTabs.value = true;
					fGetPlanRedpackList();
				} else {
					isShowTabs.value = false;
					const current = oRedPack.current + 1;
					apiRedpackList({
						shopRoleId: UserInfo.roleId,
						current,
						size: oRedPack.size,
						tabs: 0
					}).then(res => {
						oRedPack.current = current;
						let tData = res && res.result ? res.result : {};
						let tRecords = tData.records ? tData.records : [];
						if (tRecords.length > 0) {
							oRedPack.list.push(...tRecords);
							if (tData.hasNext) {
								oRedPack.finished = false;
							} else {
								oRedPack.finished = true;
							}
						} else {
							oRedPack.finished = true;
						}
						oRedPack.loading = false;
					}).catch(() => {
						oRedPack.loading = false;
						oRedPack.error = true;
					});
				}
			};


			// 方案
			const oRedPackFollow = reactive({
				current: 0,
				size: 10,
				list: [],
				loading: false,
				finished: true
			});
			// 加载
			const fOnLoadFollow = () => {
				console.log("fOnLoadFollow");
				if (route_query.planId || route_query.shopPlanId) {
					isShowTabs.value = true;
					fGetPlanRedpackList();
				} else {
					isShowTabs.value = false;
					const current = oRedPackFollow.current + 1;
					apiRedpackList({
						shopRoleId: UserInfo.roleId,
						current,
						size: oRedPackFollow.size,
						tabs: 1
					}).then(res => {
						oRedPackFollow.current = current;
						let tData = res && res.result ? res.result : {};
						let tRecords = tData.records ? tData.records : [];
						if (tRecords.length > 0) {
							oRedPackFollow.list.push(...tRecords);
							if (tData.hasNext) {
								oRedPackFollow.finished = false;
							} else {
								oRedPackFollow.finished = true;
							}
						} else {
							oRedPackFollow.finished = true;
						}
						oRedPackFollow.loading = false;
					}).catch(() => {
						oRedPackFollow.loading = false;
						oRedPackFollow.error = true;
					});
				}
			};

			// 选中的红包
			const oSelectRedPack = ref({});
			// 选中的红包的当前状态
			const sSelectRedPackStatus = ref("INIT");
			// 选中的红包的打开状态
			const sSelectRedPackOpenStatus = ref("INIT");
			// 打开红包
			const fOpenRedPack = (row) => {
				oSelectRedPack.value = row;
				Toast({
					type:'loading'
				});
				apiRedpackOpen({
					redpackId: row.redpackId,
					roleId: UserInfo.roleId,
				}).then(res => {
					console.log(res);

					let tData = res && res.result ? res.result : {};
					if (tData.openStatus === 'NOPERM') {
						oDialogA.showDialog= true;
						oDialogA.title = "只有跟单者才可以抢红包哦~"
					} else if (tData.openStatus === 'GAINABLE') {
						oDialogA.showDialog = true;
						oDialogA.title = "已跟单成功，请回到彩店中去抢红包"
					} else {
						// Toast('已领取')
					}
				})
			};

			// 抢红包
			const fLoot = () => {
				sSelectRedPackOpenStatus.value = "LOADING"
				apiRedpackLoot({
					lootLicense: oSelectRedPack.value.lootLicense,
					redpackId: oSelectRedPack.value.redpackId,
					shopRoleId: UserInfo.roleId
				}).then(res => {
					// SUCCESS成功,OVERDUE过期,NONE空了
					let tLootStatus = res && res.result && res.result.redpackLootStatusEnum ? res.result
						.redpackLootStatusEnum : "SUCCESS";
					if (tLootStatus === 'SUCCESS') {
						fGoDetail();
					} else {
						sSelectRedPackOpenStatus.value = "END";
					}
				}).catch(() => {
					oDialogB.showDialog = false;
				})
			};
			// 前往红包详情
			const fGoDetail = () => {
				console.log(oSelectRedPack)
				router.push({
					path: "/detailHB",
					query: {
						redpackId: oSelectRedPack.value.redpackId,
						shopRedpackId: oSelectRedPack.value.shopRedpackId
					}
				})
			};
			// oDialogA
			const oDialogA = reactive({
				showDialog: false,
				showCancelButton: false,
				showConfirmButton: false,
				title:''
			});
			// oDialogC
			const oDialogC = reactive({
				showDialog: false,
				showCancelButton: false,
				showConfirmButton: false
			});
			// 打开规则
			const fOpenRule = () => {
				oDialogC.showDialog = true
			};
			// 弹框关闭之前
			const fBeforeClose = (action, oDialog, fn) => {
				const {
					dialogKey
				} = oDialog;
				console.log(action, oDialog, fn)
				if (dialogKey === "oDialogA") {
					if (action === "confirm") {
						fnJumpApp(oSelectRedPack.value.planId)
						oDialogA.showDialog = false;
						fn(false)
					} else if (action === "cancel") {
						oDialogA.showDialog = false;
						fn(false)
					}
				} else if (dialogKey === "oDialogC") {
					oDialogC.showDialog = false;
					fn(false)
				}
			};
			const fBeforeClose1 = () =>{
				console.log(route_query);
				fnCopyInnerText( route_query.shopPlanId)
				location.href =UserInfo.androidAccountInfo.h5Url + route_query.shopPlanId
			}

			// 首次切换van-tabs组件
			const fOnRendered = (Name) => {
				console.log(Name)
				if (Name === "zero") {
					oRedPack.finished = true
				} else if (Name === "one") {
					oRedPackFollow.finished = false
				}
			};

			// oDialogB
			const oDialogB = reactive({
				showDialog: false,
				showCancelButton: false,
				showConfirmButton: false
			});

			/*
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				fnIsHasHistory("/sendHBPw");
			};
			return {
				active,
				isShowTabs,
				fOnRendered,
				defaultAvatar,

				oRedPack,
				fOnLoad,

				oRedPackFollow,
				fOnLoadFollow,
				fBeforeClose1,

				fOpenRedPack,

				oSelectRedPack,
				sSelectRedPackStatus,
				sSelectRedPackOpenStatus,
				fLoot,
				fGoDetail,
				isApp,
				oDialogA,
				oDialogB,
				oDialogC,
				fBeforeClose,
				fLeft,
				fOpenRule
			};
		}
	}
</script>

<style scoped lang="scss">
	.redPack {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		background: #F1F2F7;

		.redPack_tips {
			margin: 0.32rem auto;
			padding: 0.1066rem 0.64rem;
			text-align: center;

			font-size: 0.32rem;
			font-weight: 400;
			color: #E2335D;
			line-height: 0.4533rem;

			background: rgba(255, 118, 113, 0.15);
			border-radius: 0.3466rem;
			border: 0.0266rem solid #FF7671;
		}

		.redPack_tips_a {
			margin-bottom: 0.8rem;

			text-align: center;
			font-size: 0.4266rem;
			font-weight: 400;
			color: #383838;
			line-height: 0.5866rem;
		}

		.redPack_tips_b {
			padding: 0.2666rem 0.64rem 0rem;
			&>p {
				margin-top: 0.4rem;
				font-size: 0.4266rem;
				font-weight: 400;
				color: #383838;
				line-height: 0.5866rem;
			}
		}

		.redPack_body--tabs {
			background: #FFFFFF;
			box-shadow: 0 0.0533rem 0.4266rem 0 rgb(224 228 246 / 50%);
			border-radius: 0.32rem 0.32rem 0 0;
		}

		.redPack_body {
			padding: 0 0.32rem;
			.redPack_list {
				display: flex;
				align-items: flex-start;
				margin-top: 0.5333rem;

				.redPack_list_a {
					width: 1.0133rem;
					height: 1.0133rem;
					border-radius: 50%;
					margin-right: 0.2133rem;
				}

				.redPack_list_b {
					flex: 1;

					.redPack_list_b_a {
						font-size: 0.3733rem;
						font-weight: 400;
						color: #383838;
						line-height: 0.5333rem;
					}

					.redPack_list_b_b {
						position: relative;

						margin-top: 0.1066rem;
						padding: 0.3733rem 0.2666rem 0.3733rem 1.92rem;
						background-image: url('~@/assets/images/img_027.png');
						background-size: 100% 100%;
						background-repeat: no-repeat;

						display: flex;
						align-items: center;
						justify-content: space-between;

						.redPack_list_b_b_a {
							.redPack_list_b_b_a_a {
								font-size: 0.6933rem;
								color: #FFFFFF;
								line-height: 0.8rem;
							}

							.redPack_list_b_b_a_b {
								font-size: 14px;
								font-family: PingFangSC-Regular, PingFang SC;
								font-weight: 400;
								color: #FFDCBE;
								line-height: 20px;
							}
						}

						.redPack_list_b_b_b {
							width: 1.5733rem;
							height: auto;
						}
					}


					.redPack_list_b_b--overdue:after {
						content: "";
						position: absolute;
						left: 0;
						right: 0;
						top: 0;
						bottom: 0;
						margin: auto;

						background: #FFFFFF;
						border-radius: 0.2133rem;
						opacity: 0.55;
					}
				}
			}
		}

		.redPack_wrapper {
			margin: 20% 0.5333rem 0;
			text-align: center;

			.redPack_wrapper_content {
				padding: 1.5733rem 0 1.04rem;
				text-align: center;

				background-image: url('~@/assets/images/img_028.png');
				background-size: 100% 100%;
				background-repeat: no-repeat;

				.redPack_wrapper_content_a {
					width: 1.8133rem;
					height: 1.8133rem;
					border-radius: 50%;
					border: 0.1333rem solid rgba(255, 225, 146, 0.35);
				}

				.redPack_wrapper_content_b {
					margin-top: 0.32rem;

					font-size: 0.4266rem;
					font-weight: 400;
					color: #FFE192;
					line-height: 0.5866rem;
				}

				.redPack_wrapper_content_c {
					margin-top: 0.3733rem;

					font-size: 0.5866rem;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #FFF6CB;
					line-height: 0.8rem;
				}

				.redPack_wrapper_content_d {
					margin-top: 0.0266rem;

					font-size: 0.9066rem;
					color: #FFF6CB;
					line-height: 1.0666rem;
				}

				.redPack_wrapper_content_e {
					display: flex;
					align-items: center;
					justify-content: center;

					margin-top: 1.4133rem;
					height: 3.0933rem;
					.redPack_wrapper_content_e_a {
						width: 3.04rem;
					}
					.redPack_wrapper_content_e_b {
						width: 1.8666rem;
					}
				}
			}

			.redPack_wrapper_contentA {
				padding: 3.12rem 0 1.04rem;
				text-align: center;

				background-image: url('~@/assets/images/img_030.png');
				background-size: 100% 100%;
				background-repeat: no-repeat;

				.redPack_wrapper_contentA_a {
					width: 1.8133rem;
					height: 1.8133rem;
					border-radius: 50%;
					border: 0.1333rem solid rgba(255, 225, 146, 0.35);
				}

				.redPack_wrapper_contentA_b {
					margin-top: 0.32rem;

					font-size: 0.4266rem;
					font-weight: 400;
					color: #FFE192;
					line-height: 0.5866rem;
				}

				.redPack_wrapper_contentA_c {
					margin-top: 0.9066rem;

					font-size: 0.5866rem;
					font-weight: 400;
					color: #FFF6CB;
					line-height: 0.8rem;
				}

				.redPack_wrapper_contentA_d {
					margin-top: 4.64rem;

					display: flex;
					align-items: center;
					justify-content: center;

					&>p {
						font-size: 0.4266rem;
						font-weight: 400;
						color: #FFE192;
						line-height: 0.5866rem;
					}

					&>img {
						width: 0.128rem;
						margin-left: 0.16rem;
					}
				}
			}

			.redPack_wrapper_close {
				margin-top: 1.12rem;
				width: 1.0133rem;
				height: 1.0133rem;
			}
		}
	}
</style>
