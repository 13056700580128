<template>
	<div class="detailHB app_flex">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>
		<div class="detailHB_head">
			<div class="detailHB_head_a">
				<img class="detailHB_head_a_a" :src="oDetailHB.starterHead ? oDetailHB.starterHead : defaultAvatar" alt="">
				<p class="detailHB_head_a_b">{{oDetailHB.starterName}}的红包</p>
			</div>


			<template v-if="oDetailHB.assetsCurrent > 0">
				<p class="detailHB_head_b">¥ {{oDetailHB.assetsCurrent}}</p>
				<p class="detailHB_head_c">已存入店内余额，可用于投注</p>
			</template>
			<p v-else class="detailHB_head_d">大吉大利，一起红单!</p>
		</div>

		<div class="detailHB_body app_scroll app_flex">
			<p class="detailHB_body_tips" v-if="!oDetailHB.self && oDetailHB.overdue">
				该红包已过期,已领取{{oDetailHB.gainedPack}}/{{oDetailHB.packCount}}个</p>
			<p class="detailHB_body_tips" v-else-if="!oDetailHB.self && oDetailHB.noneTime > 0">
				{{oDetailHB.packCount}}个红包,{{fFormatTime(oDetailHB.noneTime)}}被抢光
			</p>
			<p class="detailHB_body_tips"
				v-else-if="!oDetailHB.self && oDetailHB.assetsCurrent>0 && oDetailHB.noneTime <= 0">
				领取{{oDetailHB.gainedPack}}/{{oDetailHB.packCount}}个</p>

			<p class="detailHB_body_tips" v-else-if="oDetailHB.self && oDetailHB.overdue">
				该红包已过期,已领取{{oDetailHB.gainedPack}}/{{oDetailHB.packCount}}个,共{{oDetailHB.gainedAssets}}/{{oDetailHB.assetsCount}}元
			</p>
			<p class="detailHB_body_tips"
				v-else-if="oDetailHB.self && oDetailHB.assetsCurrent > 0 && oDetailHB.noneTime > 0">
				{{oDetailHB.packCount}}个红包共{{oDetailHB.assetsCount}}元,{{fFormatTime(oDetailHB.noneTime)}}被抢光
			</p>
			<p class="detailHB_body_tips" v-else-if="oDetailHB.self && oDetailHB.assetsCurrent > 0">
				已领取{{oDetailHB.gainedPack}}/{{oDetailHB.packCount}}个,共{{oDetailHB.gainedAssets}}/{{oDetailHB.assetsCount}}元
			</p>
			<p class="detailHB_body_tips" v-else>领取{{oDetailHB.gainedPack}}/{{oDetailHB.packCount}}个</p>


			<div class="app_scroll">
				<div class="detailHB_list" v-for="(item, index) in aPersonnel" :key="index">
					<div class="detailHB_list_a">
						<img class="detailHB_list_a_a" :src="item.head ? item.head : defaultAvatar" alt="">
						<div class="detailHB_list_a_b">
							<p class="detailHB_list_a_b_a">{{item.name}}</p>
							<p class="detailHB_list_a_b_b">{{fnFormatData(item.gainTime,"hh:mm")}}</p>
						</div>
					</div>
					<div class="detailHB_list_b">
						<p class="detailHB_list_b_a">¥{{item.assets}}</p>
						<div class="detailHB_list_b_b" v-if="item.optimal">
							<img class="detailHB_list_b_b_a" src="~@/assets/icon/icon-021.png" alt="">
							<p class="detailHB_list_b_b_b">手气最佳</p>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import {
		ref,
		reactive
	} from 'vue';
	import {
		useStore
	} from "vuex";
	import {
		useRoute
	} from "vue-router";
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import {
		fnFormatData
	} from "@/utils/public.js";
	import {
		apiRedpackDetail
	} from '@/api/redPage.js';
	import defaultAvatar from "@/assets/images/img_032.png";
	export default {
		name: "DetailHB",
		setup() {
			const route = useRoute();
			const store = useStore(); // 使用useStore方法
			const UserInfo = store.state.userInfo.userInfo;
			const route_query = route.query;
			const oCPData = reactive({
				oNCData: {
					redpackId: route_query.redpackId,
					shopRedpackId: route_query.shopRedpackId,
					shopRoleId: UserInfo.roleId,
				},
				oYCData: {}
			});

			// 红包信息
			const oDetailHB = ref({});
			// 抢红包的人的信息
			const aPersonnel = ref([]);
			// 获取红包详情=
			// 获取红包详情
			const fGetRedPackDetail = () => {
				console.log(oCPData.oNCData)
				apiRedpackDetail({
					...oCPData.oNCData
				}).then(res => {
					console.log(res);
					let tData = res && res.result ? res.result : {};
					oDetailHB.value = tData;
					oDetailHB.value.assetsCurrent = tData.assetsCurrent ? tData.assetsCurrent : 0
					aPersonnel.value = tData.redpackDetailRanks ? tData.redpackDetailRanks : [];
				})
			};
			fGetRedPackDetail();

			// 格式化时间
			const fFormatTime = (Time) => {
				let tStr = '';
				Time -= 0;
				if (Time > 3600) {
					let h = Math.floor(Time / 3600);
					tStr = h + '小时';
					Time = Time % 3600;
				}
				if (Time > 60) {
					let s = Math.floor(Time / 60);
					tStr += s + '分';
					Time = Time % 60;
				}
				if (Time > 0) {
					tStr += Time + '秒';
				}
				return tStr;
			}
			/*
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				fnIsHasHistory("/");
			};
			return {
				defaultAvatar,
				
				oDetailHB,
				aPersonnel,

				fnFormatData,
				fFormatTime,
				fLeft
			}
		}
	}
</script>

<style scoped lang="scss">
	.detailHB {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		background: #F1F2F7;

		background-image: url('~@/assets/images/img_031.png');
		background-size: 100% 7.0666rem;
		background-repeat: no-repeat;

		.detailHB_head {
			margin-top: 0.1333rem;
			margin-bottom: 2.16rem;
			text-align: center;

			.detailHB_head_a {
				display: flex;
				align-items: center;
				justify-content: center;

				.detailHB_head_a_a {
					width: 1.28rem;
					height: 1.28rem;
					border-radius: 50%;
					border: 0.1333rem solid rgba(255, 225, 146, 0.35);
				}

				.detailHB_head_a_b {
					margin-left: 0.48rem;

					font-size: 0.4266rem;
					font-weight: 400;
					color: #FFE192;
					line-height: 0.5866rem;
				}
			}

			.detailHB_head_b {
				margin-top: 0.5333rem;

				font-size: 0.9066rem;
				font-weight: bold;
				color: #FFF6CB;
				line-height: 1.0666rem;
			}

			.detailHB_head_c {

				font-size: 0.32rem;
				font-weight: 400;
				color: #FFF6CB;
			}

			.detailHB_head_d {
				margin-top: 0.8rem;
				margin-bottom: 0.3733rem;

				font-size: 0.5866rem;
				font-weight: 500;
				color: #FFF6CB;
				line-height: 0.8rem;
			}
		}

		.detailHB_body {
			padding: 0.5333rem 0.32rem 0;
			background: #FFFFFF;

			.detailHB_body_tips {
				font-size: 0.32rem;
				font-weight: 400;
				color: #979797;
				line-height: 0.4533rem;
				margin-bottom: 0.32rem;
			}

			.detailHB_list {
				padding: 0.2666rem;
				margin-bottom: 0.32rem;
				box-shadow: 0px 0.0533rem 0.2133rem 0px rgba(224, 228, 246, 0.5);
				border-radius: 0.2133rem;

				display: flex;
				align-items: center;
				justify-content: space-between;

				.detailHB_list_a {
					display: flex;
					align-items: center;

					.detailHB_list_a_a {
						width: 1.0133rem;
						height: 1.0133rem;
						border-radius: 50%;
						margin-right: 0.2933rem;
					}

					.detailHB_list_a_b {
						.detailHB_list_a_b_a {
							font-size: 0.3733rem;
							font-weight: 400;
							color: #383838;
							line-height: 0.5333rem;
						}

						.detailHB_list_a_b_b {
							margin-top: 0.1066rem;
							font-size: 0.32rem;
							font-weight: 400;
							color: #979797;
							line-height: 0.3733rem;
						}
					}
				}

				.detailHB_list_b {
					display: flex;
					flex-direction: column;
					align-items: flex-end;

					.detailHB_list_b_a {
						font-size: 0.4266rem;
						font-weight: bold;
						color: #E2335D;
						line-height: 0.5066rem;
					}

					.detailHB_list_b_b {
						display: flex;
						align-items: center;

						.detailHB_list_b_b_a {
							width: 0.32rem;
							height: auto;
						}

						.detailHB_list_b_b_b {
							margin-left: 0.1066rem;

							font-size: 0.2666rem;
							font-weight: 400;
							color: #383838;
							line-height: 0.3733rem;
						}
					}
				}
			}
		}
	}
</style>
