<!-- 发红包 -->
<template>
	<div class="sendHB app_flex">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="发红包" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>

		<div class="sendHB_body">
			<div class="sendHB_field_a">
				<p class="sendHB_field_a_a">店内余额：<em>￥{{oCPData.oNCData.available}}</em></p>
				<span class="sendHB_field_a_b" @click="fGoRecharge">立即充值</span>
			</div>
			<van-form class="sendHB_form" @submit="fSubmit" :border="false">
				<van-field v-model="oForm.redpackAssets" input-align="right" type="number" label="红包金额"
					placeholder="请输入红包金额" @update:model-value="fUpdateMoney" @blur="fBlurMoney">
					<template #extra>
						<span class="sendHB_field_extra">元</span>
					</template>
				</van-field>

				<van-field v-model="oForm.redpackCount" input-align="right" type="number" label="红包个数"
					placeholder="请输入红包个数" @update:model-value="fUpdateCount" @blur="fBlurCount">
					<template #extra>
						<span class="sendHB_field_extra">个</span>
					</template>
				</van-field>

				<div class="sendHB_field_b">
					<span class="sendHB_field_b_a">抢红包</span>
					<span class="sendHB_field_b_b">跟单可抢</span>
				</div>
				<div class="sendHB_field_c">
					<span class="sendHB_field_b_a">大吉大利，一起红单！</span>
				</div>

				<div class="sendHB_field_d">
					<span>{{oForm.redpackAssets ? oForm.redpackAssets : 0}}</span>
				</div>

				<div class="sendHB_submit">
					<van-button round block native-type="submit" class="app_button"
						:disabled="oCPData.oYCData.isDisable">
						塞钱进红包
					</van-button>
				</div>
			</van-form>

			<p class="sendHB_tips">未领取红包，将于24小时后发起退款</p>
		</div>
	</div>
</template>

<script>
	import {
		ref,
		reactive
	} from "vue";
	import {
		useStore
	} from "vuex";
	import {
		useRoute,
		useRouter
	} from "vue-router";
	import {
		Toast
	} from 'vant';
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import {
		fnClearNoInteger
	} from "@/utils/public.js";
	import {
		apiGetPlanDetailNew
	} from '@/api/plan.js';
	import {
		apiGetBaseInfo,
		apiPublish
	} from '@/api/redPage.js';
	import {
		apiGetRechargeWay
	} from '@/api/other.js';
	export default {
		name: "SendHB",
		setup() {
			const route = useRoute();
			const route_query = route.query;
			const router = useRouter();
			const store = useStore();
			const UserInfo = store.state.userInfo.userInfo;
			// 初始信息
			const oCPData = reactive({
				oNCData: {
					available: 0
				},
				oYCData: {
					isDisable: true
				},
			});
			// 方案详情
			const oPlanDetail = ref({});
			// 获取方案详情
			const fGetPlanDetail = () => {
				Toast({
					type: "loading"
				});
				apiGetPlanDetailNew({
					id: route_query.planId,
					userId: UserInfo.userId,
					currentRoleId: UserInfo.roleId,
				}).then(res => {
					oPlanDetail.value = res && res.result ? res.result : {};
				})
			};
			// 获取初始信息
			const fGetBaseInfo = () => {
				apiGetBaseInfo({
					id: UserInfo.roleId
				}).then(res => {
					let tData = res && res.result ? res.result : {};
					console.log(tData)
					oCPData.oNCData.available = tData.available;
				})
			};
			//表单字段
			const oForm = reactive({
				redpackAssets: null,
				redpackCount: null,
			});
			// 是否禁用
			const fIsDisable = () => {
				if (oForm.redpackAssets > 0 && oForm.redpackCount > 0) {
					oCPData.oYCData.isDisable = false
				} else {
					oCPData.oYCData.isDisable = true
				}
			};
			// 编辑-红包金额
			const fUpdateMoney = (Value) => {
				// 只能输入整数
				let aData = Number(fnClearNoInteger(Value));
				// 保留最大长度6位
				if (aData <= 0) {
					oForm.redpackAssets = '';
				} else if (aData > Number(oCPData.oNCData.available)) {
					oForm.redpackAssets = Math.floor(oCPData.oNCData.available);
				} else {
					oForm.redpackAssets = aData + '';
				}
				fIsDisable();
			};
			// 失焦事件-红包金额
			const fBlurMoney = () => {
				// 只能输入整数
				let aData = Number(fnClearNoInteger(oForm.redpackAssets));
				if (aData <= 0) {
					oForm.redpackAssets = '';
				} else if (aData > Number(oCPData.oNCData.available)) {
					oForm.redpackAssets = Math.floor(oCPData.oNCData.available);
				} else {
					// 清空输入框 再进行赋值，这个操作为了防止小数点保留
					oForm.redpackAssets = "";
					oForm.redpackAssets = aData + '';
				}
				fIsDisable()
			};

			// 编辑-红包个数
			const fUpdateCount = (Value) => {
				// 只能输入整数
				oForm.redpackCount = Number(fnClearNoInteger(Value));
				if (oForm.redpackCount <= 0) {
					oForm.redpackCount = ''
				}
				fIsDisable()
			};
			// 失焦事件-红包个数
			const fBlurCount = () => {
				// 只能输入整数
				let aData = Number(fnClearNoInteger(oForm.redpackCount));
				// 清空输入框
				oForm.redpackCount = "";
				// 再进行赋值，这个操作为了防止小数点保留
				oForm.redpackCount = aData;
				if (oForm.redpackCount <= 0) {
					oForm.redpackCount = ''
				}
				fIsDisable()
			};

			// 表单提交触发事件
			const fSubmit = () => {
				if (oForm.redpackAssets > 0 && oForm.redpackCount > 0) {
					oCPData.oYCData.isDisable = false;
				} else {
					return oCPData.oYCData.isDisable = true;
				}
				Toast({
					type: "loading"
				});
				let tPlanDetail = oPlanDetail.value;
				apiPublish({
					createrHead: tPlanDetail.roleDetail.head,
					createrId: tPlanDetail.roleDetail.roleId,
					createrName: tPlanDetail.roleDetail.roleName,
					shopPlanId: tPlanDetail.planDetail.id,
					redpackAssets: oForm.redpackAssets,
					redpackCount: oForm.redpackCount,
					ruleAptitude: 0,
					ruleStatus: 0
				}).then(() => {
					Toast({
						message: "发布成功",
						position: 'bottom'
					});
					fnIsHasHistory("/");
				})
			};

			const oRechargeWay = reactive({
				rechargeUrl: null,
				rechargeWay: "NORMAL"
			});
			const fGetRechargeWay = () => {
				apiGetRechargeWay().then(res => {
					let tData = res.result ? res.result : {};
					oRechargeWay.rechargeUrl = tData.rechargeUrl;
					oRechargeWay.rechargeWay = tData.rechargeWay;
				})
			};
			// 前往路径
			const fGoRecharge = () => {

				if ( oRechargeWay.rechargeWay === "QRCODE" ) {
					router.push({
						path: '/web',
						query: {
							type: "recharge",
							url: oRechargeWay.rechargeUrl,
							redirect: "goback"
						}
					});
				} else {
					router.replace({
						path: "/recharge"
					})
				}
			};
			/*
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				fnIsHasHistory("/sendHBPw");
			};

			fGetPlanDetail();
			fGetBaseInfo();
			fGetRechargeWay();
			return {
				oCPData,
				oForm,

				fUpdateMoney,
				fBlurMoney,

				fUpdateCount,
				fBlurCount,

				fGoRecharge,
				fSubmit,
				fLeft
			}
		}
	}
</script>

<style scoped lang="scss">
	.sendHB {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		background: #F1F2F7;

		.sendHB_body {
			margin: 0.32rem 0.32rem 0;

			.sendHB_field_a {
				display: flex;
				align-items: center;
				justify-content: space-between;

				background: rgba(255, 255, 255, 0.5);
				border-radius: 0.72rem;
				border: 0.0266rem solid #FFFFFF;
				padding: 0.2933rem;

				.sendHB_field_a_a {
					font-size: 0.3733rem;
					font-weight: 400;
					color: #383838;
					line-height: 0.5333rem;

					&>em {
						color: #E2335D;
					}
				}

				.sendHB_field_a_b {
					padding: 0.16rem 0.3733rem;

					background: linear-gradient(180deg, #FF7671 0%, #E2335D 100%);
					border-radius: 0.56rem;

					font-size: 0.3733rem;
					font-weight: 500;
					color: #FFFFFF;
					line-height: 0.5333rem;
				}
			}

			.sendHB_field_b {
				padding: 0.4266rem;

				background: #FFFFFF;
				border-radius: 0.2133rem;

				display: flex;
				align-items: center;
				justify-content: space-between;

				.sendHB_field_b_a {
					font-size: 0.4266rem;
					font-weight: 400;
					color: #383838;
					line-height: 0.5866rem;
				}

				.sendHB_field_b_b {
					padding: 0.0266rem 0.1333rem;

					border-radius: 0.1066rem;
					border: 0.0266rem solid #FF7671;

					font-size: 0.3733rem;
					font-weight: 400;
					color: #FF7671;
					line-height: 0.5333rem;
				}
			}

			.sendHB_field_c {
				margin-top: 0.32rem;
				padding: 0.4266rem;

				background: #FFFFFF;
				border-radius: 0.2133rem;

				text-align: right;
				font-size: 0.4266rem;
				font-weight: 400;
				color: #383838;
				line-height: 0.5866rem;
			}

			.sendHB_field_d {
				margin-top: 1.28rem;
				text-align: center;

				&>span {
					font-size: 1.3333rem;
					font-weight: bold;
					color: #383838;
					line-height: 1.5466rem;

					position: relative;
				}

				&>span:after {
					content: "￥";
					position: absolute;
					left: -1.0666rem;
					top: 0;
					bottom: 0;
					margin: auto 0;

					font-size: 0.9066rem;
					font-weight: bold;
					color: #383838;
				}
			}

			.sendHB_submit {
				margin: 0.64rem 2.6666rem;
			}

			.sendHB_tips {
				margin-top: 0.4rem;
				text-align: center;
				font-size: 0.32rem;
				font-weight: 400;
				color: #979797;
				line-height: 0.4533rem;
			}
		}
	}
</style>
