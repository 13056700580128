<template>
    <div :style="isApp ? 'height: 100%;padding-top: 1.3rem;box-sizing: border-box;' : 'height: 100%;'">
        <div class="app_title_b">
            <van-sticky>
                <van-nav-bar title="红包" right-text="红包规则" left-arrow @click-left="fLeft"
                             @click-right="fOpenRule" />
            </van-sticky>
        </div>
        <div class="hb_box" id="scroll" @scroll="listenBottomOut">
            <div class="hb_box_item" v-for="(item,index) in oDialogC.redData" :key="index">
                <div class="box_item_l">
                    <img src="~@/assets/images/new/new_019.png" alt="" class="item_l_tou" v-if="!item.createrHead">
                    <img :src="item.createrHead" alt="" class="item_l_tou" v-else>

                </div>
                <div class="box_item_r" @click="toDetail(item.planId)">
                    <div class="item_r_box">
                        <div class="box_item_name">
                            {{item.createrName}}
                        </div>
                        <div class="bang_item_right1" v-if="item.evenRedCount > 0">
                            <div>
                                <img src="~@/assets/images/new/new_005.png" alt="">
                                <div class="font_box">
                                    <span style="font-size: 0.32rem;color: #E2335D;margin-right: 0.053rem">{{item.evenRedCount}}</span><span>连胜</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item_r_hb">
                        <img src="~@/assets/images/new/new_018.png" alt="" class="">
                        <div>¥{{item.redpackAssets}}</div>
                    </div>

                </div>

            </div>
        </div>
        <h-dialog-a title="红包规则" dialogKey="oDialogC" :show="oDialogC.showDialog" :showCancelButton="oDialogC.showCancelButton" :showConfirmButton="oDialogC.showConfirmButton" @fBeforeClose="fBeforeClose">
            <div class="redPack_tips_b">
                <p>1、必须跟单后才可领取对应发单的红包</p>
                <p>2、同一个发单不论跟几次单,同一个红包只能领取一次</p>
                <p>3、同一个发单如果发了多个红包,只要跟单后每个红包都可领取一次</p>
            </div>
        </h-dialog-a>
    </div>
</template>

<script>
    import {
        fnIsHasHistory
    } from "@/utils/router.js";
    import {
        uaFun
    } from "@/utils/public.js"
    import {
        apiRedpackList
    } from '@/api/redPage';
    import HDialogA from "@/components/HDialog/HDialogA/index.vue";
    import {reactive} from "vue";
    import {Toast} from "vant";
    import {useRouter} from "vue-router";
    export default {
        name: "redPackList",
        components: {
            HDialogA
        },
        setup() {
            const isApp = uaFun()
            const router = useRouter();
            const toDetail = (Id) =>{
                // 跳转到方案详情
                router.push({
                    path: '/planDetail',
                    query: {
                        id: Id
                    }
                })
            }
            const listenBottomOut = () =>{

                //正文总高度
                // console.log(document.getElementById("scroll").scrollTop);
                let scrollHeight = document.getElementById("scroll").scrollHeight;
                //元素可见区域高度
                let offsetHeight = document.getElementById("scroll").offsetHeight;
                //可滚动容器超出当前窗口显示范围的高度
                let scrollTop = document.getElementById("scroll").scrollTop;
                //避免切换时读取到异常高度
                if (scrollTop == 0) {
                    scrollHeight= 10000;
                }
                //scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现scrollHeight< (offsetHeight+ scrollTop)的情况，严格来讲，是接近底部。
                //console.log(scrollHeight + " " + offsetHeight+ " " + scrollTop);

                // console.log("加载中~");
                if (scrollTop >= scrollHeight - offsetHeight) {
                    if(oDialogC.total != oDialogC.redData.length){
                        //此处添加自定义操作
                        oDialogC.size += 20
                        apiRedpackListFun()
                    }

                }


            };
            const fLeft = () => {
                fnIsHasHistory("/sendHBPw");
            };
            // 打开规则
            const fOpenRule = () => {
                oDialogC.showDialog = true
            };
            const oDialogC = reactive({
                showDialog: false,
                showCancelButton: false,
                showConfirmButton: false,
                size:20,
                total:0,
                redData:[]
            });
            const fBeforeClose = () => {
                oDialogC.showDialog = false;
            };
            const apiRedpackListFun = () =>{
                Toast({
                    type: "loading"
                });
                apiRedpackList({
                    current:1,
                    size:oDialogC.size
                }).then(res=>{
                    oDialogC.redData = res.result.records
                    oDialogC.total = res.result.total
                    console.log(res.result.records);
                })
            }
            apiRedpackListFun()
            return {
                fLeft,
                toDetail,
                fBeforeClose,
                oDialogC,
                isApp,
                listenBottomOut,
                fOpenRule
            }
        }
    }
</script>

<style scoped lang="scss">
    .redPack_tips_b {
        padding: 0.2666rem 0.64rem 0rem;
    &>p {
         margin-top: 0.4rem;
         font-size: 0.4266rem;
         font-weight: 400;
         color: #383838;
         line-height: 0.5866rem;
     }
    }
    .hb_box{
        width: 100%;
        height:  calc(100% - 1.1733rem);
        padding-top: 0.32rem;
        box-sizing: border-box;
        overflow-y: auto;
        .hb_box_item{
            width: calc(100% - 0.853rem);
            height: 1.493rem;
            margin: 0 auto ;
            border-bottom: 0.027rem solid rgba(245, 245, 245, 1);
            display: flex;
            justify-content: space-between;
            .box_item_l{
                height: 100%;
                width: 1.013rem;
                display: flex;
                align-items: center;
                .item_l_tou{
                    width: 1.013rem;
                    height: 1.013rem;
                    margin-right: 0.293rem;
                    border-radius: calc( 1.013rem / 2);
                }

            }
            .box_item_r{
                height: 100%;
                width: calc(100% - 1.306rem);
                display: flex;
                align-items: center;
                justify-content: space-between;
                .item_r_box{
                    display: flex;
                    .box_item_name{
                        font-size: 0.373rem;
                        font-weight: 400;
                        color: #383838;
                        margin-right: 0.107rem;
                    }
                    .bang_item_right1{
                        /*width: 1.067rem;*/
                        height: 100%;
                        padding-top: 0.32rem;
                        box-sizing: border-box;
                        margin-right: 0.267rem;
                        div{
                            height: 0.667rem;
                            position: relative;
                            img{
                                width: 1.067rem;
                                height: 0.667rem;
                            }
                            .font_box{
                                width: 100%;
                                height: 0.667rem;
                                position: absolute;
                                top: 0;
                                right: 0;
                                line-height: 0.973rem;
                                text-align: center;
                                span{
                                    font-size: 0.213rem;
                                    font-weight: 500;
                                    color: #383838;
                                }
                            }
                        }

                        /*height: 0.667rem;*/
                    }
                }
                .item_r_hb{
                    display: flex;
                    img{
                        width: 0.427rem;
                        height: 0.507rem;
                        margin-right: 0.107rem;
                    }
                    div{
                        font-size: 0.427rem;
                        font-weight: bold;
                        color: #E2335D;
                    }
                }

            }
        }
    }
</style>
