import request from '@/utils/request'
import consts from '@/utils/consts'
// 查询方案列表
export function apiGetPlanOnePage(data) {
	return request({
		url: consts.businessPublic + '/plan/getPlanOnePage',
		method: 'post',
		data: data
	})
}

// 已有方案ID获取方案支付明细
export function apiPlanPayDetail(data) {
	return request({
		url: consts.businessPublic + '/plan/payDetail',
		method: 'post',
		data: data
	})
}

// 方案下注关联彩票店
export function apiPlanLotteryShop(data) {
	return request({
		url: consts.businessPublic + '/plan/planLotteryShop',
		method: 'post',
		data: data
	})
}

// 支付前盘口校验
export function apiOrderPayPlateCheck(data) {
	return request({
		url: consts.businessPublic + '/order/payPlateCheck',
		method: 'post',
		data: data
	})
}
// 订单支付
// 订单支付新增返回字段，只有code200，并且payResult为true时才代表支付成功，code200并且payResult代表支付失败，具体错误信息看失败原因枚举字段
/**
 * [aOrderPay  订单支付]
 * @param  {[Object]} data [ 请求参数 ]
 * @return {
		"code": "200",提示信息
		"message": "操作成功!",状态码
		"result": {返回业务数据
			"message": "",支付失败原因message
			"orderUnPayReasonEnum": "",支付失败类型
			"payResult": true 支付是否成功,true:成功;false:失败
		},
		"success": true 请求成功与否,true:成功,false:失败
	} [ 相应参数 ]
 * orderUnPayReasonEnum 字段补充说明
 *   PAID(1, "您已完成支付，请勿重复支付"),
 *   PAY_NONSUPPORT(11, "暂不支持该支付方式"),
 *   AMOUNT_ERROR(21, "金额错误"),
 *   AMOUNT_NOT_ENOUGH(31, "余额不足"),
 *   HAVE_UN_PAY_FEE(41,"有未支付的佣金"),
 *   ORDER_PAY_ERROR(51,"彩店出票失败")
 */
export function apiOrderPay(data) {
	return request({
		url: consts.businessPublic + '/order/pay',
		method: 'post',
		data: data
	})
}


// 查询方案详情新
export function apiGetPlanDetailNew(data) {
	return request({
		url: consts.businessAnonymous + '/plan/getPlanDetail',
		method: 'post',
		data: data
	})
}
// 方案小票诉求
export function planTicketAppeal(data) {
	return request({
		url: consts.businessPublic + '/plan/planTicketAppeal',
		method: 'post',
		data: data
	})
}

/////////////////////////////////////////////////////

// 跟单大厅列表
export function getHallPlanList(data) {
	return request({
		url: consts.businessAnonymous + '/plan/getHallPlanList',
		method: 'post',
		data: data
	})
}
// 跟单大厅关注列表
export function queryFocusRolePlan(data) {
	return request({
		url: consts.businessPublic + '/plan/queryFocusRolePlan',
		method: 'post',
		data: data
	})
}
// 跟单详情消息列表
export function listComment(data) {
	return request({
		url: consts.businessAnonymous + '/communication/listComment',
		method: 'post',
		data: data
	})
}
//消息回复详情
export function queryReplyDetail(data) {
	return request({
		url: consts.businessAnonymous + '/communication/queryReplyDetail',
		method: 'post',
		data: data
	})
}
//消息回复列表
export function listReply(data) {
	return request({
		url: consts.businessAnonymous + '/communication/listReply',
		method: 'post',
		data: data
	})
}
// 跟单详情评论
export function saveComment(data) {
	return request({
		url: consts.businessPublic + '/communication/saveComment',
		method: 'post',
		data: data
	})
}
// 跟单详情评论点赞
export function saveAgree(data) {
	return request({
		url: consts.businessPublic + '/communication/saveAgree',
		method: 'post',
		data: data
	})
}
// 举报信息列表
export function listAccuseInfo(data) {
	return request({
		url: consts.businessPublic + '/communication/listAccuseInfo',
		method: 'post',
		data: data
	})
}
// 举报信息列表
export function accuseReply(data) {
	return request({
		url: consts.businessPublic + '/communication/accuseReply',
		method: 'post',
		data: data
	})
}
// 删除评论
export function delReply(data) {
	return request({
		url: consts.businessPublic + '/communication/delReply',
		method: 'post',
		data: data
	})
}
// 赛事支持查询
export function showSupportNum(data) {
	return request({
		url: consts.businessAnonymous + '/communication/showSupportNum',
		method: 'post',
		data: data
	})
}
// 赛事支持
export function supportMatch(data) {
	return request({
		url: consts.businessPublic + '/communication/supportMatch',
		method: 'post',
		data: data
	})
}
