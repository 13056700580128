<template>
	<div class="recordHB app_flex">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="红包记录" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>
		<div class="recordHB_body app_scroll">
			<van-tabs v-model:active="active" swipeable title-active-color="#383838" title-inactive-color="#979797"
				line-width="0.48rem" @rendered="fOnRendered">
				<van-tab title="发出的红包">
					<div class="app_flex">
						<div class="recordHB_send_total">
							<p class="recordHB_send_total_a">发出红包总额</p>
							<p class="recordHB_send_total_b"><em>¥</em>{{oGiveOut.total.assetsCount}}</p>
							<p class="recordHB_send_total_c">{{oGiveOut.total.redpackCount}}<em>个</em></p>
							<p class="recordHB_send_total_d">发出红包数</p>
						</div>

						<div class="recordHB_send_content app_scroll">
							<van-list v-model:loading="oGiveOut.loading" v-model:error="oGiveOut.error"
								error-text="请求失败，点击重新加载" :finished="oGiveOut.finished" finished-text="没有更多了"
								@load="fOnLoadGiveOut">
								<div class="recordHB_send_list" v-for="(item, index) in oGiveOut.list" :key="index"
									@click="fGoDetail(item)">
									<div class="recordHB_send_list_a">
										<p class="recordHB_send_list_a_a">￥{{item.assets}}</p>
										<p class="recordHB_send_list_a_b">
											{{fnFormatData(item.time,"yyyy-MM-dd hh:mm")}}</p>
									</div>
									<div class="recordHB_send_list_b">
										<p class="recordHB_send_list_b_b" v-if="item.overdue">已过期 {{item.redpackGained}}
											/ {{item.redpackCount}} 个</p>
										<p class="recordHB_send_list_b_a" v-else>
											<em>{{item.redpackGained}} / {{item.redpackCount}}</em>个
										</p>
									</div>
								</div>
							</van-list>
						</div>
					</div>
				</van-tab>
				<van-tab title="抢到的红包">
					<div class="app_flex">
						<div class="recordHB_send_total">
							<p class="recordHB_send_total_a">收到红包总额</p>
							<p class="recordHB_send_total_b"><em>¥</em>{{oReceive.total.assetsCount}}</p>
							<div class="recordHB_send_total_e">
								<div>
									<p class="recordHB_send_total_c">{{oReceive.total.redpackCount}}<em>个</em></p>
									<p class="recordHB_send_total_d">抢到的红包数</p>
								</div>
								<div>
									<p class="recordHB_send_total_c">{{oReceive.total.mvpCount}}<em>次</em></p>
									<p class="recordHB_send_total_d">手气最佳</p>
								</div>
							</div>
						</div>

						<div class="recordHB_send_content app_scroll">
							<van-list v-model:loading="oReceive.loading" v-model:error="oReceive.error"
								error-text="请求失败，点击重新加载" :finished="oReceive.finished" finished-text="没有更多了"
								@load="fOnLoadReceive">
								<div class="recordHB_send_list" v-for="(item, index) in oReceive.list" :key="index"
									@click="fGoDetail(item)">
									<div class="recordHB_send_list_c">
										<p class="recordHB_send_list_c_a">{{item.roleName}}</p>
										<p class="recordHB_send_list_c_b">
											{{fnFormatData(item.time,"yyyy-MM-dd hh:mm")}}</p>
									</div>
									<div class="recordHB_send_list_d">
										<p class="recordHB_send_list_d_a">¥{{item.assets}}</p>
									</div>
								</div>
							</van-list>
						</div>
					</div>
				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script>
	import {
		ref,
		reactive
	} from 'vue';
	import {
		useStore
	} from "vuex";
	import {
		useRouter
	} from "vue-router";
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import {
		fnFormatData
	} from "@/utils/public.js";
	import {
		apiGiveOutList,
		apiReceiveList
	} from "@/api/redPage.js";
	export default {
		name: "RecordHB",
		setup() {
			const router = useRouter();
			const store = useStore();
			const UserInfo = store.state.userInfo.userInfo;
			const active = ref(0);

			// 发出
			const oGiveOut = reactive({
				current: 0,
				size: 10,
				list: [],
				total: {
					assetsCount: 0,
					redpackCount: 0,
				},
				loading: false,
				finished: false
			});
			// 加载完成--发出
			const fOnLoadGiveOut = () => {
				const current = oGiveOut.current + 1;
				apiGiveOutList({
					shopRoleId: UserInfo.roleId,
					current,
					size: oGiveOut.size
				}).then(res => {
					oGiveOut.current = current;
					let tData = res && res.result ? res.result : {};
					oGiveOut.total.assetsCount = res.result && res.result.assetsCount ? res.result
						.assetsCount : 0;
					oGiveOut.total.redpackCount = res.result && res.result.redpackCount ? res.result
						.redpackCount : 0;
					let tRecords = tData.records && tData.records.records ? tData.records.records : [];
					if (tRecords.length > 0) {
						oGiveOut.list.push(...tRecords);
						if (tData.hasNext) {
							oGiveOut.finished = false;
						} else {
							oGiveOut.finished = true;
						}
					} else {
						oGiveOut.finished = true;
					}
					oGiveOut.loading = false;
				}).catch(() => {
					oGiveOut.loading = false;
					oGiveOut.error = true;
				})
			};


			// 抢到
			const oReceive = reactive({
				current: 0,
				size: 10,
				list: [],
				total: {
					assetsCount: 0,
					redpackCount: 0,
					mvpCount: 0,
				},
				loading: false,
				finished: true
			});
			// 加载完成--抢到
			const fOnLoadReceive = () => {
				const current = oReceive.current + 1;
				apiReceiveList({
					shopRoleId: UserInfo.roleId,
					current,
					size: oReceive.size
				}).then(res => {
					oReceive.current = current;
					let tData = res && res.result ? res.result : {};
					oReceive.total.assetsCount = res.result && res.result.assetsCount ? res.result
						.assetsCount : 0;
					oReceive.total.redpackCount = res.result && res.result.redpackCount ? res.result
						.redpackCount : 0;
					oReceive.total.mvpCount = res.result && res.result.mvpCount ? res.result.mvpCount : 0;
					let tRecords = tData.records && tData.records.records ? tData.records.records : [];
					if (tRecords.length > 0) {
						oReceive.list.push(...tRecords);
						if (tData.hasNext) {
							oReceive.finished = false;
						} else {
							oReceive.finished = true;
						}
					} else {
						oReceive.finished = true;
					}
					oReceive.loading = false;
				}).catch(() => {
					oReceive.loading = false;
					oReceive.error = true;
				})
			};

			// 首次切换van-tabs组件
			const fOnRendered = (Name) => {
				if (Name === 0) {
					oGiveOut.finished = false
				} else if (Name === 1) {
					oReceive.finished = false
				}
			};


			const fGoDetail = (Detail) => {
				router.push({
					path: "/detailHB",
					query: {
						redpackId: Detail.redpackId,
						shopRoleId: UserInfo.roleId
					}
				})
			};
			/*
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				fnIsHasHistory("/mine");
			};
			return {
				active,
				fOnRendered,
				oGiveOut,
				fOnLoadGiveOut,

				oReceive,
				fOnLoadReceive,

				fGoDetail,

				fnFormatData,
				fLeft
			}
		}
	}
</script>

<style scoped lang="scss">
	.recordHB {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		background: linear-gradient(180deg, #FFFFFF 0%, #F1F2F7 100%);

		.recordHB_body {
			margin-top: 0.8533rem;
			background: #FFFFFF;
			box-shadow: 0px 0.0533rem 0.4266rem 0px rgba(224, 228, 246, 0.5);
			border-radius: 0.32rem 0.32rem 0px 0px;

			.recordHB_send_total {
				margin-top: 0.96rem;
				text-align: center;

				.recordHB_send_total_a {
					font-size: 0.3733rem;
					font-weight: 400;
					color: #979797;
				}

				.recordHB_send_total_b {
					font-size: 1.3333rem;
					font-weight: bold;
					color: #383838;
					line-height: 1.5466rem;

					&>em {
						font-size: 0.9066rem;
						font-weight: bold;
						color: #383838;
					}
				}

				.recordHB_send_total_c {
					margin-top: 0.5866rem;
					font-size: 0.8rem;
					font-weight: bold;
					color: #383838;

					&>em {
						font-size: 0.32rem;
					}
				}

				.recordHB_send_total_d {
					margin-top: 0.1066rem;
					font-size: 0.32rem;
					font-weight: 400;
					color: #383838;
				}

				.recordHB_send_total_e {
					display: flex;
					align-items: center;
					justify-content: space-evenly;
				}
			}

			.recordHB_send_content {
				margin: 0.8533rem 0.32rem 0;

				.recordHB_send_list {
					display: flex;
					align-items: center;
					justify-content: space-between;

					padding: 0.2933rem;
					background: #FFFFFF;
					box-shadow: 0px 0.0533rem 0.2133rem 0px rgba(224, 228, 246, 0.5);
					border-radius: 0.2133rem;

					margin: 0.32rem 0;

					.recordHB_send_list_a {
						.recordHB_send_list_a_a {
							font-size: 0.4266rem;
							font-weight: bold;
							color: #E2335D;
							line-height: 0.5066rem;
						}

						.recordHB_send_list_a_b {
							margin-top: 0.0533rem;
							font-size: 0.2666rem;
							font-weight: 400;
							color: #979797;
							line-height: 0.3733rem;
						}
					}

					.recordHB_send_list_b {
						.recordHB_send_list_b_a {
							font-size: 0.32rem;
							font-weight: 400;
							color: #383838;

							&>em {
								color: #E2335D;
							}
						}

						.recordHB_send_list_b_b {
							font-size: 0.32rem;
							font-weight: 400;
							color: #383838;
						}
					}

					.recordHB_send_list_c {
						.recordHB_send_list_c_a {
							font-size: 0.3733rem;
							font-weight: 400;
							color: #383838;
							line-height: 0.5333rem;
						}

						.recordHB_send_list_c_b {
							margin-top: 0.1066rem;
							font-size: 0.2666rem;
							font-weight: 400;
							color: #979797;
						}
					}

					.recordHB_send_list_d {
						font-size: 0.4266rem;
						font-weight: bold;
						color: #E2335D;
					}
				}
			}
		}
	}
</style>
